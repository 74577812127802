@font-face {
  font-family: 'Bit5x3';
  src: url('Bit5x3.eot');
  src: url('Bit5x3.eot?#iefix') format('embedded-opentype'),
    url('Bit5x3.woff2') format('woff2'), url('Bit5x3.woff') format('woff'),
    url('Bit5x3.ttf') format('truetype'), url('Bit5x3.svg#Bit5x3') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
